import "./siteTitle.css"
import siteLog from './images/indiaBharatTravel.png'

export function RenderSiteTitle(){
    return (
        <div className="siteTitle">
            <img src={siteLog} alt="logo" className="siteLogo"></img>
            <p className="siteName"> INDIA BHARAT TRAVEL </p>
        </div>
    )
}