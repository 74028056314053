import './App.css';
import { RenderStatesNavBar } from './navbar/states';
import { RenderSiteTitle } from './common/renderSiteTitle';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import PlacesPage from './places/placesView';
import { StateNameProvider } from './context/stateContext';

function App() {
  return (
    <Router>
      <StateNameProvider>
        <div class ="display-page">
          <RenderSiteTitle/>
          <RenderStatesNavBar/>
          <Routes >
            <Route path='/places/:stateId' element={<PlacesPage/>} />
          </Routes >
        </div> 
      </StateNameProvider>
    </Router>
  );
}

export default App;
