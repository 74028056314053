import React, { createContext, useState, useContext } from 'react';

const StateNameContext = createContext();

export function useStateName() {
    return useContext(StateNameContext);
}

export function StateNameProvider({ children }) {
    const [stateName, setStateName] = useState('');

    return (
        <StateNameContext.Provider value={{ stateName, setStateName }}>
            {children}
        </StateNameContext.Provider>
    );
}